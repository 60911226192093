html {
  height: 100%;
}
#root {
  /* height: 100%; */
  flex-grow: 1;
}

body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1200px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .show-small {
    display: block;
  }
  .hide-small {
    display: none;
  }
}
@media (min-width: 725px) {
  .show-small {
    display: none;
  }
  .hide-small {
    display: block;
  }
}
